import React from "react";

export function IRSmallLogo(): React.ReactElement {
  return (
    <svg
      data-name="Italian Roots"
      height="22.447"
      viewBox="0 0 28.322 22.447"
      width="28.322"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M117.265 74.817a4.935 4.935 0 002.6 2.459 9.487 9.487 0 003.377.545 10.954 10.954 0 003.929-.675l1.072-.438-.421-1.072a11.484 11.484 0 00-4.987-5.35 4.888 4.888 0 00-3.579-.041 3.747 3.747 0 00-.617.387 9.361 9.361 0 00-12.272 3.185A11.482 11.482 0 00109.73 89.7a13.917 13.917 0 007.618 2.274 14.176 14.176 0 002.932-.308 13.8 13.8 0 007.861-4.836 3.742 3.742 0 001.028.2 3.667 3.667 0 10-.007-7.333h-.089a3.571 3.571 0 00-2.535 1.113 3.5 3.5 0 00-.5 4.353 11.312 11.312 0 01-4.227 3.182 9.782 9.782 0 00-1.918-5.908 10.094 10.094 0 00-6.22-3.624l-1.391-.267-.092 1.418a10.068 10.068 0 001.932 6.933 9.2 9.2 0 002.706 2.339 11.254 11.254 0 01-5.617-1.784 8.789 8.789 0 01-2.583-12.156 6.759 6.759 0 014.264-2.929 6.834 6.834 0 014.261.517 3.448 3.448 0 00.112 1.933zm2.2-1.836a1.148 1.148 0 01.651-.579 2.513 2.513 0 01.894-.154 2.382 2.382 0 01.918.171 9.118 9.118 0 013.11 2.925 8.978 8.978 0 01-4.264-.212 2.552 2.552 0 01-1.356-1.2 1.112 1.112 0 01.046-.95zm8.738 9.443a1.3 1.3 0 01.976-.408 1.4 1.4 0 01.935.366 1.369 1.369 0 01.031 1.925 1.4 1.4 0 01-1.925.024 1.348 1.348 0 01-.017-1.908zm-12.1 2.939a7.092 7.092 0 01-1.391-3.675 6.772 6.772 0 014.6 5.953 7.062 7.062 0 01-3.2-2.278z"
        data-name="Path 60"
        fill="#c49a6c"
        transform="translate(-104.507 -69.528)"
      />
    </svg>
  );
}
